
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import variables from '../../styles/variables.scss';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'Header',
  setup() {
    // router
    const router = useRouter();

    // store
    const store = useStore();
    const {layout} = store.state as RootStoreState;

    const sidebarCollapsed = computed(() => {
      return layout.sidebarCollapsed;
    });

    const onLogout = () => {
      localStorage.removeItem('token');
      router.push('/login');
    };

    return {
      sidebarCollapsed,
      onLogout,
      ...variables,
    };
  },
});
