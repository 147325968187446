
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import variables from '@/styles/variables.scss';
import logo from '@/assets/logo.svg';
import MenuItemIcon from '@/components/icon/MenuItemIcon.vue';
import {getPrimaryPath} from '@/utils/path';

export default defineComponent({
  name: 'Sidebar',
  components: {
    MenuItemIcon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const {layout} = store.state as RootStoreState;
    const {menuItems} = layout;
    const storeNamespace = 'layout';

    const activePath = computed<string>(() => {
      return getPrimaryPath(route.path);
    });

    const sidebarCollapsed = computed<boolean>(() => layout.sidebarCollapsed);

    const toggleIcon = computed<string[]>(() => {
      if (sidebarCollapsed.value) {
        return ['fas', 'indent'];
      } else {
        return ['fas', 'outdent'];
      }
    });

    const onMenuItemClick = (item: MenuItem) => {
      router.push(item.path);
    };

    const toggleSidebar = () => {
      store.commit(`${storeNamespace}/setSideBarCollapsed`, !sidebarCollapsed.value);
    };

    return {
      sidebarCollapsed,
      toggleIcon,
      menuItems,
      logo,
      activePath,
      onMenuItemClick,
      toggleSidebar,
      ...variables,
    };
  },
});
