
import {defineComponent, PropType} from 'vue';
import Icon from '@/components/icon/Icon.vue';
import Tab from '@/components/tab/Tab.vue';

export default defineComponent({
  name: 'ActionTab',
  components: {Tab},
  props: {
    icon: {
      type: [String, Array] as PropType<Icon>,
    },
    title: {
      type: String,
    }
  },
  emits: [
    'click',
  ],
  setup(props: ActionTabProps, {emit}) {
    const onClick = () => {
      emit('click');
    };

    return {
      onClick,
    };
  },
});
