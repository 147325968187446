
import {computed, defineComponent} from 'vue';
import Header from './components/Header.vue';
import Sidebar from './components/Sidebar.vue';
import {useStore} from 'vuex';
import TabsView from '@/layouts/components/TabsView.vue';

export default defineComponent({
  name: 'BasicLayout',
  components: {
    TabsView,
    Header,
    Sidebar,
  },
  setup() {
    const store = useStore();
    const {layout} = store.state as RootStoreState;

    const sidebarCollapsed = computed<boolean>(() => layout.sidebarCollapsed);

    return {
      sidebarCollapsed,
    };
  }
});
